import React from "react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import { MediumContainer, PaddingContainer } from "../styled"
import theme from "../theme"
import ReactHtmlParser from "react-html-parser"
var MarkdownIt = require('markdown-it'),
    md = new MarkdownIt();


const OurFounder = ( {title, content} ) => {
	return (
		<FounderSection>
			<PaddingContainer>
				<ContentContainer>
					<div className="left">
						<StaticImage
							src="../images/founder.jpg"
							quality={95}
							formats={["auto", "webp", "avif"]}
							alt=""
							className="image"
							style={{position:"absolute"}}
						/>
						</div>
					<div className="right">
						<h2>{title}</h2>
						<div>{ReactHtmlParser(content)}</div>
					</div>
				</ContentContainer>
			</PaddingContainer>
		</FounderSection>
	)
}

export default OurFounder

const FounderSection = styled.section`
	position:relative;
	width:100%;
	background-color:${theme.colors.lightGrey};
`

const ContentContainer = styled(MediumContainer)`
	color:${theme.colors.black};
	z-index:2;
	position:relative;
	display:flex;
	justify-content:space-between;
	padding:50px 0;
	width:100%;
	align-items:center;
	>div, >div p, >div span {
		font-family: "Founders" !important;
		font-size:16px !important;
	}
	.left {
		padding-right:5%;
		width:30%;
		padding-top:30%;
		position:relative;
		border: 10px solid ${theme.colors.white};
		.image {
			width: 100%;
			height: 100%;
			position:absolute;
			top:0;
		}
	}
	.right {
		text-align:right;
		width: 65%;
	}
	

	h2 {
		/* color:${theme.colors.pink}; */
	}
	@media(max-width:620px) {
		flex-direction:column;
		.left {
			width:100%;
			padding-top:120%;
			.image {
				
			}
		}
		.right {
			width:100%;
			margin-top:20px;
		}
	}
`
