import React from "react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import { MediumContainer } from "../styled"
import theme from "../theme"


const Hero = ( {title, content} ) => {
	return (
		<HeroSection>
			
			<MediumContainer>
				<div className="padding-container">
					<ContentContainer>
						<div className="left">
							<p>{content}</p>
							<h1>{title}</h1>
						</div>
						<div className="right">
						<StaticImage
							src="../images/hero-image.jpg"
							quality={95}
							formats={["auto", "webp", "avif"]}
							alt=""
							className="right-image"
						/>
						</div>
					</ContentContainer>
				</div>
			</MediumContainer>
			
		</HeroSection>
	)
}

export default Hero

const HeroSection = styled.section`
	position:relative;
	width:100%;
	overflow:hidden;
	display:flex;
	background-color:black;
	.padding-container {
		min-height: 40vw;
		padding-left:50px;
	}
	@media(max-width: 560px) {
		.padding-container {
			padding-left:20px;
			padding-right:20px;
		}
	}
	
`
const ContentContainer = styled(MediumContainer)`
	color:white;
	z-index:2;
	position:relative;
	width:100%;
	margin-left:0;
	display:flex;
	align-items:center;

	.left {
		padding-top:40px;
		padding-bottom:40px;
		max-width:360px;
		h1 {
			color:${theme.colors.pink};
		}
	}
	.right {
		height:100%;
		.right-image {
			height: 40vw;
			@media(max-width: 762px) {
					height:46vw;;
			}
		}
	}
	@media(max-width: 762px) {
		.left {
			p {
				font-size:.8rem;
				line-height:1.2;
			}
			h1 {
				font-size:1.3rem;
			}
		}
		.right {
			.right-image {

			}
		}
	}
	@media(max-width:550px) {
		flex-direction:column;

		.left {
			padding-bottom: 0;
			p {
				font-size:1rem;
			}
		}
		.right {
			height:100%;
			.right-image {
				width: 100%;
    		height: auto;
			}
		}
	}
	
`