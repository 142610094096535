import * as React from "react"
import Hero from "../components/Hero"
import OurStory from "../components/OurStory"
import OurFounder from "../components/OurFounder"
import OurApp from "../components/OurApp"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import axios from "axios"

const appTitle = "Our App"
const appContent = "Want to be among the first to test the app?"

const IndexPage =  () => {

	const [content, setContent] = React.useState([]);

	React.useEffect(() => {
		axios
			.get(`https://kosmique-rest-server.herokuapp.com/api/web-content`)
			.then((response) => {
				console.log("Response: ", response.data)
				const heroContent = response.data.find(obj => obj.id === "cFuzuwkzLkNUoysXglJ6")
				const founderContent = response.data.find(obj => obj.id === "k4SLzqRThu1DTHHt6a18")
				const storyContent = response.data.find(obj => obj.id === "pLgTDNLtQKa4kDh5li2I")
				setContent({heroContent, founderContent, storyContent})
			})
			.catch((error) => {
				console.log('Errors: ', error)
			});
	},[])
	
	return (
		<Layout>
			<Seo title="Home" />
			{content.heroContent && <Hero title={content.heroContent.title} content={content.heroContent.bodyCopy} /> }
			<a id="our-story" />
			{content.storyContent && <OurStory title={content.storyContent.title} content={content.storyContent.bodyCopy} /> }
			{content.founderContent && <OurFounder title={content.founderContent.title} content={content.founderContent.bodyCopy} /> }
			{content.heroContent && <OurApp title={appTitle} content={appContent} /> }
			<a id="footer" />
			{content.heroContent && <Footer /> }
		</Layout>
	)
}

export default IndexPage

