import React, { useRef, useState, useEffect } from "react"
import styled from "@emotion/styled"
import theme from "../theme"
import axios from "axios"
import * as qs from "query-string"

const SignupForm = () => {

	return (
		<>
			<Form
				data-netlify="true"
				method="POST"
				name="Signup"
			>
			 <input type="hidden" name="form-name" value="Signup" />
				<div>
					<input
						name="email"
						type="email"
						placeholder="Email Address"
					/>
				</div>
				<div>
					<input
						type="submit"
						value="SUBMIT"
					/>
				</div>
			</Form>
		</>
	)
}

export default SignupForm

const Form = styled.form`
	display:flex;
	margin:auto;
	justify-content:center;
	input[type=email] {
		width:340px;
		border:solid 1px ${theme.colors.grey};
		padding:6px 14px 4px;
		font-size: .75rem;
	}
	input[type=submit] {
		background-color: ${theme.colors.pink};
    border: none;
    padding: 7px 20px 8px;
    font-size: .65rem;
    width: 170px;
    height: 38px;
		font-weight:bold;
		font-family: "Maax";
	}
	@media(max-width:572px) {
		flex-direction:column;
		input[type=email], input[type=submit] {
				width:100%;
		}
		input[type=email] {
			margin-bottom:10px;
		}
	}
`
