import React from "react"
import styled from "@emotion/styled"
import SignupForm from "./SignupForm"
import { MediumContainer, PaddingContainer } from "../styled"
import theme from "../theme"
import ReactHtmlParser from "react-html-parser"

const OurApp = ( {title, content} ) => {
	return (
		<MediumContainer>
			<PaddingContainer>
				<ContentContainer>
					<h2>{title}</h2>
					<h4>{ReactHtmlParser(content)}</h4>
				</ContentContainer>
				<FormContainer>
					<h5>Sign up to be an exclusive MVP test user.</h5>
					<SignupForm />
					</FormContainer>
			</PaddingContainer>
			<LegalContainer>
			<p className="legal">We promise to respect your inbox. By submitting your email, you agree to receive email communication from Kosmique from time to time. You can opt outat any time.</p>
			</LegalContainer>
		</MediumContainer>			
	)
}

export default OurApp

const ContentContainer = styled(MediumContainer)`
		color:${theme.colors.black};
		z-index:2;
		position:relative;
		margin-left:0;
		margin:70px 0;
		text-align:center;
		h2 {
			color:${theme.colors.black};
			margin-bottom:.6rem;
		}
		h4 {
			color:${theme.colors.pink};
			font-size:1.5rem;
		}
`
const FormContainer = styled(MediumContainer)`
	text-align:center;
	h5 {
		color:${theme.colors.black};
		font-family: "Founders";
		font-weight: normal;
	}
`
const LegalContainer = styled(MediumContainer)`
	p {
		padding:20px 0;
		font-size:10px;
		max-width:400px;
		line-height:1.1;
		margin:auto;
		text-align:center;
	}
`