import React from "react"
import styled from "@emotion/styled"
import { MediumContainer, PaddingContainer } from "../styled"
import theme from "../theme"
import ReactHtmlParser from "react-html-parser"

var MarkdownIt = require('markdown-it'),
    md = new MarkdownIt();

const OurStory = ( {title, content} ) => {
	return (
		<MediumContainer>
			<ContentContainer>
				<h2>{title}</h2>
				<div>{ReactHtmlParser(content)}</div>
			</ContentContainer>
		</MediumContainer>
			
	)
}

export default OurStory

const ContentContainer = styled(PaddingContainer)`
	color:${theme.colors.black};
	z-index:2;
	position:relative;
	margin-left:0;
	margin:70px 0;
	font-size:16px !important;
	h2 {
		color:${theme.colors.black};
	}
`